
import BudgetsDevolutionsBooksService from "@/modules/budgets-devolutions-books/budgets-devolutions-books-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            BudgetsDevolutionsBooksService.create(payload).then(response => {
                resolve({
                    message: "BudgetsDevolutionsBooks criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar BudgetsDevolutionsBooks",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            BudgetsDevolutionsBooksService.update(payload).then(response => {
                resolve({
                    message: "BudgetsDevolutionsBooks atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar BudgetsDevolutionsBooks",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id_budgets_devolutions,id_budgets_books_books,id_budgets_books_budgets) {
        return new Promise((resolve, reject) => {
            BudgetsDevolutionsBooksService.remove(id_budgets_devolutions,id_budgets_books_books,id_budgets_books_budgets).then(response => {
                resolve({
                    message: "BudgetsDevolutionsBooks removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover BudgetsDevolutionsBooks",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id_budgets_devolutions,id_budgets_books_books,id_budgets_books_budgets) {
        return new Promise((resolve, reject) => {
            BudgetsDevolutionsBooksService.delete(id_budgets_devolutions,id_budgets_books_books,id_budgets_books_budgets).then(response => {
                resolve({
                    message: "BudgetsDevolutionsBooks deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar BudgetsDevolutionsBooks",
                    success: false,
                    error
                });
            })
        })
    },
    get(id_budgets_devolutions,id_budgets_books_books,id_budgets_books_budgets) {
        return new Promise((resolve, reject) => {
            BudgetsDevolutionsBooksService.get(id_budgets_devolutions,id_budgets_books_books,id_budgets_books_budgets).then(response => {
                resolve({
                    message: "BudgetsDevolutionsBooks encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsDevolutionsBooks",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            BudgetsDevolutionsBooksService.paginate(query).then(response => {
                resolve({
                    message: "BudgetsDevolutionsBooks encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsDevolutionsBooks",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            BudgetsDevolutionsBooksService.all().then(response => {
                resolve({
                    message: "BudgetsDevolutionsBooks encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsDevolutionsBooks",
                    success: false,
                    error
                });
            })
        })
    }
}
