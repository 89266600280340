
import BudgetsDevolutionsService from "@/modules/budgets-devolutions/budgets-devolutions-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            BudgetsDevolutionsService.create(payload).then(response => {
                resolve({
                    message: "BudgetsDevolutions criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar BudgetsDevolutions",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            BudgetsDevolutionsService.update(payload.id,payload).then(response => {
                resolve({
                    message: "BudgetsDevolutions atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar BudgetsDevolutions",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            BudgetsDevolutionsService.remove(id).then(response => {
                resolve({
                    message: "BudgetsDevolutions removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover BudgetsDevolutions",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            BudgetsDevolutionsService.delete(id).then(response => {
                resolve({
                    message: "BudgetsDevolutions deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar BudgetsDevolutions",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            BudgetsDevolutionsService.get(id).then(response => {
                resolve({
                    message: "BudgetsDevolutions encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsDevolutions",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            BudgetsDevolutionsService.paginate(query).then(response => {
                resolve({
                    message: "BudgetsDevolutions encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsDevolutions",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            BudgetsDevolutionsService.all().then(response => {
                resolve({
                    message: "BudgetsDevolutions encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsDevolutions",
                    success: false,
                    error
                });
            })
        })
    }
}
